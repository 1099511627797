import React, { PureComponent } from "react";
import { Link, Navigate } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  CardHeader,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import {
  faRedo,
  faPlusCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";

import { axios } from "../../../../config";
import { browserStorageHelper, commonHelpers } from "../../../../helpers";
import { AlertComponent } from "../../../../components";

class AddBillingSystem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      practiceId: "",
      athenaSystemAppClientId: "",
      athenaSystemAppClientSecret: "",
      athenaAuthorizeEndpoint: "",
      athenaTokenEndpoint: "",
      optionsFacilities: [],
      selectedOptionFacilities: "",
      redirectUrl: "/admin/billingsystem",
      msg: {
        msgLoading: "",
        msgError: "",
        msgSuccess: "",
        msgWarning: "",
      },
    };
    this.renderRedirect = this.renderRedirect.bind(this);
    this.validator = new SimpleReactValidator({
      className: "text-danger",
      element: (message, className) => (
        <div className={className || "text-danger font-weight-bold"}>
          <FontAwesomeIcon icon={faExclamationTriangle} size="1x" /> {message}
        </div>
      ),
      autoForceUpdate: this,
      messages: {
        required: "Please enter a valid :attribute.",
      },
    });
  }

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount() {

  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const self = this;
    let {
      practiceId,
      athenaSystemAppClientId,
      athenaSystemAppClientSecret,
      athenaTokenEndpoint,
      athenaAuthorizeEndpoint,
    } = this.state;
    if (this.validator.allValid()) {
      self.setState({
        msg: commonHelpers.setMsg("Adding billing system...", "", "", ""),
      });
      const token = browserStorageHelper.getItem("token");
      const userId = browserStorageHelper.getItem("uid");
      const requestToken = commonHelpers.generateRequestToken();
      const postData = {
        user_id: userId,
        practice_id: practiceId,
        athena_system_app_client_id: athenaSystemAppClientId,
        athena_system_app_client_secret: athenaSystemAppClientSecret,
        athena_authorize_endpoint: athenaAuthorizeEndpoint,
        athena_token_endpoint: athenaTokenEndpoint,
        request_token: requestToken,
      };

      const axiosOptions = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/admin/billingsystem`,
          postData,
          axiosOptions
        )
        .then((res) => {
          self.setState({
            msg: commonHelpers.setMsg(
              "",
              "",
              "Billing system created successfully.",
              ""
            ),
          });
          const redirect = true;
          const redirectUrl = self.state.redirectUrl;
          self.setState({ redirect, redirectUrl });
        })
        .catch((err) => {
          self.setState({
            msg: commonHelpers.errMessage(err),
          });
        });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  renderRedirect = () => {
    const { redirect, redirectUrl } = this.state;
    if (redirect) {
      return <Navigate to={redirectUrl} />;
    }
  };

  render() {
    const {
      athenaSystemAppClientSecret,
      practiceId,
      athenaAuthorizeEndpoint,
      athenaTokenEndpoint,
      athenaSystemAppClientId,
      msg,
      optionsFacilities,
      selectedOptionFacilities,
    } = this.state;
    return (
      <div>
        {this.renderRedirect()}
        <div className="container-fluid mt-3">
          <Row className="py-3">
            <Col md="12">
              <Card>
                <CardHeader className="card-header-tab z-index-6">
                  <Row>
                    <Col size="12" lg="9" className="mb-2 font-weight-bold">
                      Add New Billing System
                    </Col>
                    <Col size="12" lg="3" className="mb-2">
                      <Link to="/admin/billingsystem">
                        <Button
                          className="mb-2 mr-2 btn-square btn-gradient-success backBtn"
                          style={{ float: "right" }}
                        >
                          <FontAwesomeIcon icon={faRedo} className="fa-fw" />{" "}
                          Back
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col size="12" lg="12" className="mb-2">
                      <Form
                        name="frmAddBillingSystem"
                        className="frmAddBillingSystem needs-validation"
                        id="frmAddBillingSystem"
                        onSubmit={this.handleSubmit}
                        noValidate
                      >
                        {" "}
                        <Row form>
                          <Col md={4} sm={12}>
                            <FormGroup>
                              <Label for="practiceId">
                                Practice Id <span className="rqfield">*</span>
                              </Label>
                              <Input
                                value={practiceId}
                                name="practiceId"
                                onChange={this.changeHandler}
                                type="text"
                                id="practiceId"
                                placeholder="Practice ID"
                                required
                              />
                              {this.validator.message(
                                "practiceId",
                                practiceId,
                                "required",
                                {
                                  messages: {
                                    required:
                                      "Please enter a valid practice id.",
                                  },
                                }
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md={4} sm={12}>
                            <FormGroup>
                              <Label for="athenaSystemAppClientId">
                                Athena System App Client ID{" "}
                                <span className="rqfield">*</span>
                              </Label>
                              <Input
                                value={athenaSystemAppClientId}
                                name="athenaSystemAppClientId"
                                onChange={this.changeHandler}
                                type="text"
                                id="athenaSystemAppClientId"
                                placeholder="Athena System App Client ID"
                                required
                              />
                              {this.validator.message(
                                "athenaSystemAppClientId",
                                athenaSystemAppClientId,
                                "required",
                                {
                                  messages: {
                                    required:
                                      "Please enter a valid athena system app client id.",
                                  },
                                }
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={4} sm={12}>
                            <FormGroup>
                              <Label for="athenaSystemAppClientSecret">
                                Athena System App Client Secret
                              </Label>
                              <Input
                                value={athenaSystemAppClientSecret}
                                name="athenaSystemAppClientSecret"
                                onChange={this.changeHandler}
                                type="textarea"
                                id="athenaSystemAppClientSecret"
                                placeholder="Athena Sytem App Client Secret"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row form>
                          <Col lg={4} md={12} sm={12}>
                            <FormGroup>
                              <Label for="athenaAuthorizeEndpoint">
                                Athena Authorization Endpoint{" "}
                              </Label>
                              <Input
                                value={athenaAuthorizeEndpoint}
                                name="athenaAuthorizeEndpoint"
                                onChange={this.changeHandler}
                                type="textarea"
                                id="athenaAuthorizeEndpoint"
                                placeholder="Athena Authorization Endpoint"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg={4} md={12} sm={12}>
                            <FormGroup>
                              <Label for="athenaTokenEndpoint">
                                Athena Token Endpoint{" "}
                                <span className="rqfield">*</span>
                              </Label>
                              <Input
                                value={athenaTokenEndpoint}
                                name="athenaTokenEndpoint"
                                onChange={this.changeHandler}
                                type="textarea"
                                id="athenaTokenEndpoint"
                                placeholder="Athena Token Endpoint"
                                required
                              />
                              {this.validator.message(
                                "athenaTokenEndpoint",
                                athenaTokenEndpoint,
                                "required|url",
                                {
                                  messages: {
                                    required:
                                      "Please enter a valid athena token endpoint.",
                                    url: "Please enter a valid athena token endpoint.",
                                  },
                                }
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row form>
                          <Col size="12" lg="12" className="mt-4">
                            <FormGroup check row>
                              <Col sm={12} className="text-right">
                                <Button className="mb-2 mr-2 btn-square btn-gradient-success">
                                  <FontAwesomeIcon
                                    icon={faPlusCircle}
                                    className="fa-fw"
                                  />{" "}
                                  Submit
                                </Button>
                              </Col>
                            </FormGroup>
                            <AlertComponent msg={msg} />
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default AddBillingSystem;
